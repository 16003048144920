import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../component/layout"
import Seo from "../component/seo"



const FaqIndex = ({
  data,
  // pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes
  
  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title="All posts" />       
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
      
      <div className="container my-3">
        <ol className="post-list">
          {posts.map(post => {
            const title = post.title
            // const blogpost =post.excerpt 
            return (
              <li key={post.uri} className="mb-3 post-list-item">
                <article
                  className=""
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <Link
                    to={post.uri}
                    itemProp="url"
                    className="text-dark"
                  >
                    <span itemProp="headline">{parse(title)}</span>
                  </Link>
                  {/* <h4>
                  </h4> */}
                  {/* <div className="row">
                    <div className="col-sm col-xs-12">
                      <header>
                      </header>
                    </div>
                  </div> */}
                </article>
              </li>
            );
          })}
        </ol>

        {/* <div className="blog-post-nav d-flex flex-wrap justify-content-between">
          {previousPagePath && (
              <Link to={previousPagePath} className="btn btn-blue btn-sm my-2">Previous page</Link>
          )}
          {nextPagePath && <Link to={nextPagePath} className="btn btn-blue btn-sm my-2">Next page</Link>}
        </div> */}

      </div>
  )
}

export default FaqIndex

export const pageQuery = graphql`
query WordPressfaq($offset: Int, $postsPerPage: Int) {
    allWpPost(
      sort: {fields: [date], order: DESC}
      limit: $postsPerPage
      skip: $offset
      filter: {categories: {nodes: {elemMatch: {name: {eq: "faq"}}}}}
    ) {
      nodes {
        uri
        title
      }
    }
  }  
`
